import { API } from "aws-amplify";
import React from "react";
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import swal from "sweetalert";
import { FormError } from "../../helpers";
import PackageCount from './../../data/packageCount.json';


const PackageSection = ({ packages, packageCount, errors, handleItems, myShipper, handlePackages, scanPackageData, packageErrors }) => {
    const addShipmentItem = (key) => {
        const newItems = [...(packages || [])];
        newItems[key] = newItems[key] || [];
        scanPackageData ? newItems[key].push({ id: 'NEW', name: 'Product', number: '', ndc: "", qty: "", tier: 0 }) : newItems[key].push({ id: 'NEW', name: 'Product', number: '' });
        handleItems(newItems)
    };

    const removeShipmentItem = (index, key) => {
        const newItems = [...(packages || [])];
        newItems[key] = newItems[key] || [];
        newItems[key].splice(index, 1);

        handleItems(newItems)
    };

    const handleItemProductChange = (index, id, name, key) => {
        const newItems = [...(packages || [])];
        newItems[key] = newItems[key] || [];
        newItems[key][index] = newItems[key][index] || {};
        newItems[key][index].id = id;
        newItems[key][index].name = name;

        handleItems(newItems)
    };

    const handleItemNumberChange = (index, value, key) => {
        const newItems = [...(packages || [])];
        newItems[key] = newItems[key] || [];
        newItems[key][index] = newItems[key][index] || {};
        newItems[key][index].number = value;

        handleItems(newItems)
    };

    const handleItemNdcChange = (index, value, key) => {
        const newItems = [...(packages || [])];
        newItems[key] = newItems[key] || [];
        newItems[key][index] = newItems[key][index] || {};
        newItems[key][index].ndc = value;

        handleItems(newItems)
    };

    const handleItemQtyChange = (index, value, key) => {
        const newItems = [...(packages || [])];
        newItems[key] = newItems[key] || [];
        newItems[key][index] = newItems[key][index] || {};
        newItems[key][index].qty = value;

        handleItems(newItems)
    };
    const handleSelectTier = (index, value, key) => {
        const newItems = [...(packages || [])];
        newItems[key] = newItems[key] || [];
        newItems[key][index] = newItems[key][index] || {};
        newItems[key][index].tier = value;

        handleItems(newItems)
    };

    const removePackage = async (key, newItems) => {
        swal({
            text: `You will lose the Package data if you continue. Are you sure you want to continue?`,
            buttons: ['No', 'Yes'],
            icon: 'error',
            dangerMode: true,
        }).then(async (status) => {
            if (status) {
                while (newItems.length > key) {
                    newItems.pop();
                }
                handlePackages(newItems, PackageCount[key]);
            }
        });
    };

    const handlePackageCount = (key) => {
        const currentItems = packages || [];
        const currentItemsCount = currentItems.length;
        let newItems = [...currentItems];

        if (currentItemsCount > key) {
            const hasValueInRemovedArray = currentItems[key].some((item) => item.number !== '');
            if (hasValueInRemovedArray) {
                removePackage(key, newItems);
                return;
            } else {
                while (newItems.length > key) {
                    newItems.pop();
                }
                handlePackages(newItems, PackageCount[key]);
            }
        }

        while (newItems.length < key) {
            newItems.push([
                {
                    id: 'rx',
                    name: 'Product',
                    number: '',
                },
            ]);
        }

        handlePackages(newItems, PackageCount[key]);
    };

    const searchProducts = async (keyword) => {
        try {
            const apiName = 'api';
            const path = `/search/product?size=1000&from=0`;
            const init = {
                body: {
                    "sort": [
                        {
                            "name.keyword": {
                                "order": "asc"
                            }
                        }
                    ],
                    "query": {
                        "bool": {
                            "must": [
                                {
                                    "match": {
                                        "shipperId": myShipper?.shipper.id
                                    }
                                }
                            ]
                        }
                    }
                },
            };
            if (keyword) {
                let fields = ["name"]
                init.body.query.bool.must.push(
                    {
                        "multi_match": {
                            "query": keyword,
                            "fields": fields
                        }
                    }
                )
            }
            const { hits } = await API.post(apiName, path, init);
            const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];

            return sourceData.map((item) => ({
                value: item.id,
                label: item.name,
            }));
        } catch (error) {
            console.error('ealstic error', error);
        }
    };

    const handleAddPackages = () => {
        let newItems = [...packages];
        newItems.push([{
            id: 'rx',
            name: 'Product',
            number: ''
        }])
        handlePackages(newItems, newItems?.length)
    }

    const removePackages = (index) => {

        const hasValueInRemovedArray = packages[index].some((item) => item.number !== '');
        if (hasValueInRemovedArray) {
            swal({
                text: `You will lose the Package data if you continue. Are you sure you want to continue?`,
                buttons: ['No', 'Yes'],
                icon: 'error',
                dangerMode: true,
            }).then(async (status) => {
                if (status) {
                    let newItems = [...packages];
                    newItems.splice(index, 1)
                    handlePackages(newItems, newItems?.length)
                }
            });
        } else {
            let newItems = [...packages];
            newItems.splice(index, 1)
            handlePackages(newItems, newItems?.length)
        }

    }

    const getErrorMessage = (pkgIndex, itemIndex, field) => {
        const error = packageErrors.find((err) => err.pkgIndex === pkgIndex && err.itemIndex === itemIndex);
        return error ? error.errors[field] : '';
    };

    return (
        <>
            <section className='mt-4'>
                <h4>Package Count </h4>
                {
                    Object.keys(PackageCount).map((key, index) => {
                        return (
                            <button key={index}
                                className={`btn btn-md btn-${packageCount === PackageCount[key] ? 'success' : 'light'} me-2 mt-2`}
                                onClick={() => handlePackageCount(key)}
                            >{PackageCount[key]}</button>
                        )
                    })
                }
            </section>
            <div className='mt-4'>
                <Row className="mb-2 d-flex  align-items-center" >
                    <Col className="auto">
                        <h4 className='mt-2'>Package Contents {`(${packages?.length} / 5)`}</h4>
                    </Col>
                </Row>
                {packageCount &&
                    Array.from({ length: packageCount }).map((_, key) => (
                        <div key={key}>
                            <Card>
                                <Table size='sm' className='mb-0'>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>#</th>
                                            <th>Product</th>
                                            <th>Reference Number{scanPackageData && <span className='text-danger'> *</span>} </th>
                                            {
                                                scanPackageData && <>
                                                    <th className="text-center">NDC  	{scanPackageData && <span className='text-danger'> *</span>} </th>
                                                    <th className="text-center">QTY  {scanPackageData && <span className='text-danger'> *</span>} </th>
                                                    <th className="text-center">Phox Tail Coverage</th>
                                                </>
                                            }
                                            <th className="text-end">
                                                {(packages?.length > 1) &&
                                                    <button className='btn btn-sm btn-light mb-2 ms-1' onClick={() => removePackages(key)}>
                                                        <i className="fe fe-trash"></i> Remove
                                                    </button>
                                                }
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {packages &&
                                            packages[key]?.map((item, index) => (
                                                <tr key={`${key}-${index}`}>
                                                    <td className='text-center'>{`${index + 1}`}</td>
                                                    <td>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            defaultOptions
                                                            value={{
                                                                label: item.name || 'Search Product',
                                                                value: item.id,
                                                            }}
                                                            placeholder='Search product'
                                                            loadOptions={searchProducts}
                                                            onChange={(e) =>
                                                                handleItemProductChange(index, e.value, e.label, key)
                                                            }
                                                        />
                                                        <FormError
                                                            error={
                                                                errors?.items?.length > 0
                                                                    ? errors?.items[index]?.id
                                                                    : null
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className={`${getErrorMessage(key, index, 'number') && "mt-4"}  form-control`}
                                                            key={index}
                                                            type='text'
                                                            name={`orderNumber-${index}`}
                                                            placeholder='e.g. 1112-12322'
                                                            value={item?.number}
                                                            onChange={(e) =>
                                                                handleItemNumberChange(index, e.target.value, key)
                                                            }
                                                        />
                                                        <FormError error={getErrorMessage(key, index, 'number')} />
                                                    </td>
                                                    {scanPackageData && <>
                                                        <td>
                                                            <input
                                                                className={`${getErrorMessage(key, index, 'ndc') && "mt-4"}  form-control`}
                                                                key={index}
                                                                type='text'
                                                                name={`ndc-${index}`}
                                                                placeholder='e.g. Metformin'
                                                                value={item?.ndc}
                                                                onChange={(e) =>
                                                                    handleItemNdcChange(index, e.target.value, key)
                                                                }
                                                            />
                                                            <FormError error={getErrorMessage(key, index, 'ndc')} />
                                                        </td>
                                                        <td>
                                                            <input

                                                                className={`${getErrorMessage(key, index, 'qty') && "mt-4"}  form-control`}
                                                                key={index}
                                                                type='text'
                                                                name={`qty-${index}`}
                                                                placeholder='e.g. 100gm'
                                                                value={item?.qty}
                                                                onChange={(e) =>
                                                                    handleItemQtyChange(index, e.target.value, key)
                                                                }
                                                            />
                                                            <FormError error={getErrorMessage(key, index, 'qty')} />
                                                        </td>
                                                        <td >
                                                            <Form.Select value={item?.tier} onChange={(e) => handleSelectTier(index, e.target.value, key)}>
                                                                <option key={0} value={0}>Tier 0: $100 </option>
                                                                <option key={1} value={1}>Tier 1: $5,000 </option>
                                                                <option key={2} value={2}>Tier 2: $20,000</option>
                                                                <option key={3} value={3}>Tier 3: $40,000 </option>
                                                                <option key={4} value={4}>Tier 4: $60,000 </option>
                                                            </Form.Select>
                                                        </td>
                                                    </>
                                                    }

                                                    <td className='text-center'>
                                                        {index === 0 && <button className='btn btn-sm btn-light mb-2 mx-1' onClick={() => addShipmentItem(key)}  >
                                                            <i className="fe fe-plus"></i>  {!scanPackageData && 'Add Item'}
                                                        </button>}
                                                        {index > 0 && (
                                                            <button
                                                                className='btn btn-sm btn-outline-secondary'
                                                                onClick={() => removeShipmentItem(index, key)}
                                                            >
                                                                <i className='fe fe-trash'></i>
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    ))
                }
            </div >

        </>
    )
};


export default PackageSection;
