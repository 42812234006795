import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Marker, Popup } from 'react-map-gl';
import { AddressView } from './Address';
import { StatusBadge } from './StatusBadge';
import { Link } from 'react-router-dom';

const statusMarkerColor = (status) => {
	let color = '#2c7be5';

	if (status === 'IN_TRANSIT') color = '#f6c343';
	else if (status === 'OUT_FOR_DELIVERY') color = '#f6c343';

	else if (status === 'DELIVERED') color = '#00d97e';

	else if (status === 'OUT_FOR_RETURN') color = '#C6ACFF';
	else if (status === 'RETURNED') color = '#824df3';

	else if (status === 'EXCEPTION') color = '#e63757';

	return `${color}`
}

export const ShipmentMarker = ({ shipment, showHyperLink = false, shipmentDetailsLatLong }) => {
	const { shipFrom, shipTo, number, status, driver, carrier } = shipment
	const { latitude, longitude } = shipTo?.address?.location || {}

	const [showPopup, setShowPopup] = useState(false);
	const handleMarkerClick = ({ originalEvent }) => { originalEvent.stopPropagation(); setShowPopup(true); };

	return latitude && longitude &&
		<Marker latitude={latitude} longitude={longitude} onClick={handleMarkerClick}>
			<svg width='24' height='24'>
				<g>
					<circle cx='12' cy='12' r='6'
						fill={statusMarkerColor(status)}
						stroke='white'
						strokeWidth='1'
					>
					</circle>
				</g>
			</svg>
			{
				showPopup && (
					<Popup className='card phox-font' latitude={latitude} longitude={longitude} offset={{ bottom: [0, -15] }} onClose={() => setShowPopup(false)} >

						<div className='card-body pb-0'>
							<Row className=''>
								<Col>
									{
										showHyperLink ?
											<Link to={`/shipment/${shipment?.id}`}  > #{number} </Link> : <p> #{number} </p>
									}
								</Col>
								<Col className='col-auto'>
									<StatusBadge status={status} />
								</Col>
							</Row>

							<hr className='p-0 m-0 my-2' />

							<strong>{shipFrom?.name}</strong>
							<AddressView address={shipFrom?.address} />

							<hr className='p-0 m-0 my-2' />

							<strong>{shipTo?.name}</strong>
							<AddressView address={shipTo?.address} mapLatLong={shipmentDetailsLatLong} />

							<hr className='p-0 m-0 my-2' />

							<strong>Driver</strong>
							<div>{driver?.name}</div>
							<div className='text-muted'>{carrier?.name}</div>
						</div>

						<hr className='p-0 m-0 my-2' />
					</Popup>
				)
			}
		</Marker>
}

export const ActualDeliveredMarker = ({ shipment }) => {

	const [showPopup, setShowPopup] = useState(false);
	const handleMarkerClick = ({ originalEvent }) => { originalEvent.stopPropagation(); setShowPopup(true); };

	let actualDeliveryLocation = null;
	shipment?.history?.map((history) => { history.status === 'DELIVERED' && (actualDeliveryLocation = history?.location) })


	return actualDeliveryLocation?.latitude && actualDeliveryLocation?.longitude &&
		<Marker latitude={actualDeliveryLocation && actualDeliveryLocation?.latitude} longitude={actualDeliveryLocation && actualDeliveryLocation?.longitude} onClick={handleMarkerClick} >
			<svg fill="#00d97e" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="24px" height="24px">
				<path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
			</svg>

			{showPopup && (
				<Popup className='card phox-font' latitude={actualDeliveryLocation?.latitude} longitude={actualDeliveryLocation?.longitude} offset={{ bottom: [0, -15] }} onClose={() => setShowPopup(false)}>
					<div className='card-body'>
						<a href={`http://maps.google.com/maps?q=${actualDeliveryLocation?.latitude},${actualDeliveryLocation?.longitude}`} target='_blank' > {`${actualDeliveryLocation?.latitude.toFixed(4)},${actualDeliveryLocation?.longitude.toFixed(4)}`} </a>
					</div>
				</Popup>
			)}
		</Marker >
}

export const ReturnedMarker = ({ shipment }) => {
	const { shipFrom, shipTo, number, driver, carrier } = shipment
	const [showPopup, setShowPopup] = useState(false);
	const handleMarkerClick = ({ originalEvent }) => { originalEvent.stopPropagation(); setShowPopup(true); };

	let returnedLocation = null;
	shipment?.history?.map((history) => { history.status === 'RETURNED' && (returnedLocation = history?.location) })

	if (returnedLocation) return returnedLocation?.latitude && returnedLocation?.longitude &&
		<Marker latitude={returnedLocation && returnedLocation?.latitude} longitude={returnedLocation && returnedLocation?.longitude} onClick={handleMarkerClick} >
			<svg width='24' height='24'>
				<g>
					<circle cx='12' cy='12' r='6'
						fill='#824df3'
						stroke='white'
						strokeWidth='1'
					>
					</circle>
				</g>
			</svg>

			{showPopup && (
				<Popup className='card phox-font' latitude={returnedLocation?.latitude} longitude={returnedLocation?.longitude} offset={{ bottom: [0, -15] }} onClose={() => setShowPopup(false)}>
					<div className='card-body pb-0'>
						<Row className=''>
							<Col><Link to={`/shipment/${shipment?.id}`} > #{number} </Link>
							</Col>
							<Col className='col-auto'>
								<StatusBadge status={'RETURNED'} />
							</Col>
						</Row>

						<hr className='p-0 m-0 my-2' />
						<strong>{shipTo?.name}</strong>
						<AddressView address={({ location: { latitude: returnedLocation?.latitude, longitude: returnedLocation?.longitude } })} mapLatLong={true} />
						<hr className='p-0 m-0 my-2' />
						<strong>{shipFrom?.name}</strong>
						<AddressView address={shipFrom?.address} />
						<hr className='p-0 m-0 my-2' />

						<strong>Driver</strong>
						<div>{driver?.name}</div>
						<div className='text-muted'>{carrier?.name}</div>
					</div>

					<hr className='p-0 m-0 my-2' />
				</Popup>
			)}
		</Marker >
}
export const OutForReturnMarker = ({ shipment }) => {
	const { shipFrom, shipTo, number, driver, carrier } = shipment
	const [showPopup, setShowPopup] = useState(false);
	const handleMarkerClick = ({ originalEvent }) => { originalEvent.stopPropagation(); setShowPopup(true); };

	let outForRetrunLocation = null;
	shipment?.history?.map((history) => { history.status === 'OUT_FOR_RETURN' && (outForRetrunLocation = history?.location) })

	if (outForRetrunLocation) return outForRetrunLocation?.latitude && outForRetrunLocation?.longitude &&
		<Marker latitude={outForRetrunLocation && outForRetrunLocation?.latitude} longitude={outForRetrunLocation && outForRetrunLocation?.longitude} onClick={handleMarkerClick} >
			<svg width='24' height='24'>
				<g>
					<circle cx='12' cy='12' r='6'
						fill='#c6acff'
						stroke='white'
						strokeWidth='1'
					>
					</circle>
				</g>
			</svg>

			{showPopup && (
				<Popup className='card phox-font' latitude={outForRetrunLocation?.latitude} longitude={outForRetrunLocation?.longitude} offset={{ bottom: [0, -15] }} onClose={() => setShowPopup(false)}>
					<div className='card-body pb-0'>
						<Row className=''>
							<Col><Link to={`/shipment/${shipment?.id}`} > #{number} </Link></Col>
							<Col className='col-auto'>
								<StatusBadge status={'OUT_FOR_RETURN'} />
							</Col>
						</Row>

						<hr className='p-0 m-0 my-2' />
						<strong>{shipTo?.name}</strong>
						<AddressView address={({ location: { latitude: outForRetrunLocation?.latitude, longitude: outForRetrunLocation?.longitude } })} mapLatLong={true} />
						<hr className='p-0 m-0 my-2' />
						<strong>{shipFrom?.name}</strong>
						<AddressView address={shipFrom?.address} />
						<hr className='p-0 m-0 my-2' />

						<strong>Driver</strong>
						<div>{driver?.name}</div>
						<div className='text-muted'>{carrier?.name}</div>
					</div>

					<hr className='p-0 m-0 my-2' />
				</Popup>
			)}
		</Marker >
}

export const ShipperMarker = ({ shipper }) => {
	const { latitude, longitude } = shipper?.address?.location || {}

	const [showPopup, setShowPopup] = useState(false);
	const handleMarkerClick = ({ originalEvent }) => { originalEvent.stopPropagation(); setShowPopup(true); };

	return latitude && longitude &&
		<Marker latitude={latitude} longitude={longitude} onClick={handleMarkerClick}>
			<svg width='24' height='24'>
				<g>
					<circle cx='12' cy='12' r='6' fill='#000000' stroke='white' strokeWidth='1'></circle>
				</g>
			</svg>
			{showPopup && (
				<Popup className='card phox-font' latitude={latitude} longitude={longitude} offset={{ bottom: [0, -15] }} onClose={() => setShowPopup(false)}>
					<div className='card-body'>
						<strong>{shipper?.name}</strong>
						<AddressView address={shipper?.address} />
					</div>
				</Popup>
			)}
		</Marker>
}

export const DriverMarker = ({ driver, count }) => {
	const latitude = driver?.location?.latitude
	const longitude = driver?.location?.longitude

	const [showPopup, setShowPopup] = useState(false);
	const handleMarkerClick = ({ originalEvent }) => { originalEvent.stopPropagation(); setShowPopup(true); };

	const getInitials = name => name.split(' ').slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('');

	return latitude && longitude && <>
		<Marker latitude={latitude} longitude={longitude} onClick={handleMarkerClick}>
			<div className={driver?.name ? `pulsing-dot` : ''}>
				<span>
					{driver?.image ? <img src={driver?.image} className="driverImage" alt="driver" /> : driver?.name ? getInitials(driver?.name) : <img src='/favicon.png' className="driverImage" alt="driver" />}
				</span>
			</div>
			{
				showPopup && (
					<Popup className='card p-0 phox-font' latitude={latitude} longitude={longitude} offset={{ bottom: [0, -15] }} onClose={() => setShowPopup(false)}>
						<div className='card-header fw-bold h-auto py-2'>{driver.name}</div>
						<div className='card-body mx-4 m-1 p-0'>
							<div>Packages: {count}</div>
							{driver?.image && <div><img className='text-center' alt='user' width='120px' height='120px' src={driver?.image} /></div>}
						</div>
					</Popup>
				)
			}
		</Marker>
	</>
}
