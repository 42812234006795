import moment from 'moment';
import React from 'react';

export const ButtonList = ({ items, value, onChange }) => {
	return <>
		{
			items && items.length > 0 && items.map((item) => {
				return (
					<div className='tooltip-wrapper'>
						<button key={item.id}
							className={`btn me-2 mb-2 btn-${item.id === value ? 'success' : 'light'} button-with-tooltip`}
							onClick={() => onChange(item)}
							disabled={item?.disabled}
						>
							{item?.disabled && <i className='fe fe-alert-triangle text-danger me-1'></i>}
							{item.name}
						</button>
						{item?.disabled && <div className="button-tooltip-text">Delivery service unavailable after  {moment(item?.pickupBy + ":00", "HH:mm").format("hh:mm A")} </div>}
					</div>
				)
			})
		}
	</>
};